import { useCampaign } from "../../../../contexts/campaign";
import { useSelectedAdvertiser } from "../../../../contexts/selectedAdvertiser";

import { categorizeFields } from "./categorizeFields";

import Tooltip from "../../../../components/tooltip";

import { ReactComponent as TooltipIcon } from "../../../../assets/icons/tooltip-icon-mui.svg";

import { parseDefaultVal } from "../../../../utils/parseDefaultValues";

import { useCampaignFields } from "../../../../services/industry-organization-campaign-fields";

const CampaignFieldsReadMode = ({
  formattedKeywords,
  isUpdatingKeywordsFailed,
}: {
  isUpdatingKeywordsFailed: boolean;
  formattedKeywords: string | undefined;
}) => {
  const { campaignId } = useCampaign();
  const { selectedAdvertiser: advertiserId } = useSelectedAdvertiser();

  const { data: campaignFields } = useCampaignFields(advertiserId, campaignId);

  const categorizedFields =
    campaignFields && categorizeFields([...campaignFields]);

  const ADVNACED_TARGETING_DATA =
    categorizedFields && categorizedFields["ADVANCE_TARGETING"];

  const GEO_TARGETING_DATA =
    categorizedFields && categorizedFields["GEO_TARGETING"];

  const addressField = GEO_TARGETING_DATA?.find(
    (field) => field.campaignFieldName.toLowerCase() === "address"
  );

  const radiusField = GEO_TARGETING_DATA?.find(
    (field) => field.campaignFieldName.toLowerCase() === "radius"
  );

  return (
    <div className=" flex ">
      <div className="flex flex-col w-[58%]">
        <h3 className="text-primary font-interSemiBold text-sm">Summary</h3>
        <p className="text-sm text-primary mt-1.5 font-interRegular">
          These parameters are set to maximize your campaign impact and reach
          effectively. You can change these settings by clicking “Edit” though
          it is recommended to keep them as these are based on your industry
          best practices to ensure optimal result for your campaign.
        </p>

        <div className="flex mt-8 items-center">
          <h3 className="text-primary font-interSemiBold text-sm mr-1.5 ">
            Audience
          </h3>
          <Tooltip
            Icon={TooltipIcon}
            className="cursor-pointer w-4 h-4 text-gray-500 fill-current self-end"
            bodyText="The settings allow you to deliver ads to people based on their geographic location and traits. This strategy helps ensure your ads reach the right audience in the right place, making your campaigns more relevant and effective"
          />
        </div>

        <p className="text-sm text-primary mt-1.5 font-interRegular">
          To ensure that your ads are reaching the right audience in the right
          places, ads will be shown around
          <span className="font-interSemiBold mx-1.5">
            {addressField?.value}
          </span>
          for a
          <span className="font-interSemiBold mx-1.5">
            {radiusField?.value}
          </span>
          mile radius targeted for audiences that meet the following traits
        </p>
        <div className="flex flex-col mt-2">
          {ADVNACED_TARGETING_DATA?.map((field) => {
            return (
              <div className="flex ">
                <p className="text-sm text-primary font-interRegular">
                  •
                  <span className="mx-1.5 text-sm text-primary font-interSemiBold">
                    {field.campaignFieldName}:
                  </span>
                  {parseDefaultVal(field.value)}
                </p>
              </div>
            );
          })}
        </div>

        <div className="flex items-center mt-8">
          <h3 className="text-primary font-interSemiBold text-sm  mr-1.5">
            Search keywords
          </h3>

          <Tooltip
            Icon={TooltipIcon}
            className="cursor-pointer w-4 h-4 text-gray-500 fill-current self-end"
            bodyText="A search keyword is what people type into search engines. The best keywords are based on relevance, volume of searches, how competitive it is to bid on, alignment to your goals and rank potential. We have selected these keywords for you based on the website you provided"
          />
        </div>
        <p className="font-interRegular text-sm text-primary mt-1.5">
          Your ads will appear for users who are actively searching for these
          topics on search engines.
        </p>
        <p className=" font-interSemiBold text-sm text-primary">
          {formattedKeywords}
        </p>
        {isUpdatingKeywordsFailed && (
          <p className="mt-5 text-sm font-interSemiBold text-colorDelete">
            Failed to update the keywords!
          </p>
        )}
      </div>
    </div>
  );
};

export default CampaignFieldsReadMode;
