import "./App.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { AuthProvider } from "./contexts/auth";
import { SelectedAdvertiserProvider } from "./contexts/selectedAdvertiser";
import { ToggleSidebarProvider } from "./contexts/toggleSidebar";
import { DomainProvider } from "./contexts/subDomainData";
import { StepProvider } from "./contexts/stepper";

import { RootNavigator } from "./navigation";
import { CampaignProvider } from "./contexts/campaign";

export const queryClient = new QueryClient({});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <StepProvider>
        <SelectedAdvertiserProvider>
          <CampaignProvider>
            <AuthProvider>
              <DomainProvider>
                <ToggleSidebarProvider>
                  <RootNavigator />
                </ToggleSidebarProvider>
              </DomainProvider>
            </AuthProvider>
          </CampaignProvider>
        </SelectedAdvertiserProvider>
      </StepProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
