import { queryClient } from "../../App";
import { useMutation, useQuery } from "@tanstack/react-query";
import { request } from "../axios";
import { ICampaignFieldsResponse } from "../industry-organization-campaign-fields";
import { ICreateBudgetResponse } from "../wizard/budget";
import { IBannerBearImagesResponse } from "../wizard/bannerbear";

export interface ICreateCampaignResponse {
  websiteId: string;
  name: string;
  status: "active" | "complete" | "cancelled";
  id: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IAllCampaignsDataResponse {
  id: number;
  name: string;
  websiteId: number;
  status: "all" | "active" | "complete" | "cancelled";
  createdAt: Date;
  updatedAt: Date;
  goal: string;
  budget: number;
  image: string;
}

type TCampaignKeywords = {
  campaignId: number;
  keywords: string[];
  createdAt: Date;
  updatedAt: Date;
};

export interface ISingleCampaignDetailsResponse {
  id: number;
  name: string;
  websiteId: number;
  status: "all" | "active" | "complete" | "cancelled";
  createdAt: Date;
  updatedAt: Date;
  campaignFieldValues: ICampaignFieldsResponse[];
  campaignKeywords: TCampaignKeywords[];
  campaignBudget: ICreateBudgetResponse;
  campaignImages: IBannerBearImagesResponse;
  websiteUrl: string;
}

export type TUpdateCampaignFields = Partial<ISingleCampaignDetailsResponse>;

export interface ICampaignStrategyInfoRequest {
  campaignId: number;
  campaignName: string | undefined;
  campaignBudget: {
    id: number;
    budget: number | undefined;
    searchAllocation: number | undefined;
    socialAllocation: number | undefined;
    displayAllocation: number | undefined;
  };
  campaignGoal: {
    id: number;
    value: string;
  };
  campaignStartDate: {
    id: number;
    value: string;
  };
  campaignEndDate: {
    id: number;
    value: string;
  };
  campaignTheme: {
    id: number;
    value: string;
  };
  campaignBudgetEstimation: {
    label: "Estimated Clicks";
    high: number;
    low: number;
  };
}

export type TCampaignField = {
  id: number;
  campaignId: number;
  campaignFieldId: number;
  value: any;
  isRequired: boolean;
  createdAt: Date;
  updatedAt: Date;
  campaignFieldName: string;
  categoryType: string;
  type: "STRING" | "DATE" | "DROPDOWN" | "MULTISELECT";
  options: any[];
  isEditible: boolean;
  isVisible: boolean;
  defaultValue: any;
  defaultValueType: any;
};
export interface ICampaignStrategyResponse {
  campaignId: number;
  campaignName: string;
  campaignBudget: {
    id: number;
    websiteId: number;
    campaignId: number;
    budget: number;
    budgetRuleId: number;
    goal: string;
    searchAllocation: number;
    socialAllocation: number;
    displayAllocation: number;
    paidEmail: number;
    createdAt: Date;
    updatedAt: Date;
  };
  campaignTheme: TCampaignField;
  campaignGoal: TCampaignField;
  campaignStartDate: TCampaignField;
  campaignEndDate: TCampaignField;
  campaignBudgetEstimation: {
    label: "Estimated Clicks";
    high: number;
    low: number;
  };
}

/* GET single campaign*/
async function getCampaignById(
  campaignId: number | undefined
): Promise<ISingleCampaignDetailsResponse> {
  return request({
    url: `campaigns/campaign/${campaignId}`,
    method: "get",
  });
}

export function useSingleCampaignDetails(
  campaignId: number | undefined,
  advertiserId: number | null
) {
  return useQuery({
    queryKey: ["campaign", advertiserId, campaignId],
    queryFn: () => getCampaignById(campaignId),
    enabled: !!campaignId && !!advertiserId,
    refetchOnWindowFocus: false,
  });
}
/* ADD a new campaign */
const createCampaign = (websiteId: number) => {
  return request({
    url: `campaigns/create/${websiteId}`,
    method: "post",
  });
};
export const useCreateCampaign = () =>
  useMutation<ICreateCampaignResponse, Error, any>({
    mutationFn: createCampaign,
  });

/* UPDATE campaign field values */
function updateCampaignFieldsValues({
  data,
}: {
  data: {
    id: number;
    campaignFieldId: number;
    value: any;
  }[];
}) {
  return request({
    method: "patch",
    url: "campaign-field-values",
    data,
  });
}

export function useUpdateCampaignFieldsValues({
  advertiserId,
  campaignId,
}: {
  advertiserId: number | null;
  campaignId: number | undefined;
}) {
  return useMutation<
    any,
    Error,
    {
      data: {
        id: number;
        campaignFieldId: number;
        value: any;
      }[];
    }
  >({
    mutationFn: updateCampaignFieldsValues,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["campaignFields", advertiserId, campaignId],
      });
    },
  });
}

/* GET all campaigns linked to an advertiser */
function getAllCampaigns(
  advertiserId: number | null
): Promise<IAllCampaignsDataResponse[]> {
  return request({
    method: "get",
    url: `campaigns/${advertiserId}`,
  });
}

export function useAllCampaigns(advertiserId: number | null) {
  return useQuery({
    queryKey: ["campaigns", advertiserId],
    queryFn: () => getAllCampaigns(advertiserId),
    enabled: !!advertiserId,
    refetchOnWindowFocus: false,
  });
}

/* Getting all campaigns associated with a website */
function getCampaignsByWebsiteId(
  websiteId: number | undefined
): Promise<IAllCampaignsDataResponse[]> {
  return request({
    method: "get",
    url: `campaigns/website/${websiteId}`,
  });
}

export function useCampaignsLinkedToWebsite(websiteId: number | undefined) {
  return useQuery({
    queryKey: ["campaigns", "website", websiteId],
    queryFn: () => getCampaignsByWebsiteId(websiteId),
    enabled: !!websiteId,
  });
}

/* Delete a campaign*/

function removeCampaign(campaignId: number | undefined) {
  return request({
    method: "delete",
    url: `campaigns/${campaignId}`,
  });
}

export function useRemoveCampaign(advertiserId: number | null) {
  return useMutation({
    mutationFn: removeCampaign,
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: ["campaigns", advertiserId],
      });
      queryClient.invalidateQueries({
        queryKey: ["campaigns", "website", advertiserId],
      });
    },
  });
}

/* CAMPAIGN Strategy Info  */
function getCampaignStrategyInfo(
  campaignId: number | undefined
): Promise<ICampaignStrategyResponse> {
  return request({
    method: "get",
    url: `campaigns/info/${campaignId}`,
  });
}

export function useCampaignStrategyInfo(
  campaignId: number | undefined,
  advertiserId: number | null
) {
  return useQuery({
    queryKey: ["campaignStrategyInfo", advertiserId, campaignId],
    queryFn: () => getCampaignStrategyInfo(campaignId),
    enabled: !!campaignId && !!advertiserId,
  });
}

/* UPDATE Campaign*/
function updateCampaign({
  campaignId,
  data,
}: {
  campaignId: number | undefined;
  data: TUpdateCampaignFields;
}) {
  return request({
    method: "patch",
    url: `campaigns/${campaignId}`,
    data,
  });
}

export function useUpdateCampaign() {
  return useMutation({
    mutationFn: updateCampaign,
  });
}

/* UPDATE campaign strategy info */
function updateCampaignStrategy({
  data,
}: {
  data: ICampaignStrategyInfoRequest;
}) {
  return request({
    method: "post",
    url: "/campaigns/update-info",
    data,
  });
}

export function useUpdateCampaignStrategy(
  advertiserId: number | null,
  campaignId: number | undefined
) {
  return useMutation({
    mutationFn: updateCampaignStrategy,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["campaignStrategyInfo", advertiserId, campaignId],
      });
      queryClient.invalidateQueries({
        queryKey: ["campaignStrategyInfo", advertiserId, campaignId],
      });
      queryClient.invalidateQueries({
        queryKey: ["bannerbearImages", campaignId],
      });
    },
  });
}
