import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useCampaign } from "../../../../contexts/campaign";
import { useSelectedAdvertiser } from "../../../../contexts/selectedAdvertiser";

import { categorizeFields } from "../campaign/categorizeFields";
import { SavePDF } from "../../../campaigns/campaignDetails/pdfDocument";

import ContentContainer from "../../../../components/contentContainer";
import RoundedButton from "../../../../components/roundedButton";
import PieChart from "../../../../components/pieChart";
import ActivitySpinnerNoBg from "../../../../components/activitySpinner/withNoBgEffect";
import ActivityIndicator from "../../../../components/activitySpinner";

import {
  useCampaignStrategyInfo,
  useSingleCampaignDetails,
  useUpdateCampaign,
} from "../../../../services/campaigns";
import { useAdvertiser } from "../../../../services/advertiser";
import { useKeywords } from "../../../../services/wizard/campaign";

import { parseDefaultVal } from "../../../../utils/parseDefaultValues";
import { formatDateWithTime } from "../../../../utils/formatDateWithTime";
import { toPng } from "html-to-image";

const Confirm = ({ setStep }: { setStep: (step: number) => void }) => {
  const navigate = useNavigate();

  const { campaignId } = useCampaign();
  const { selectedAdvertiser } = useSelectedAdvertiser();

  const {
    mutateAsync: updateCampaign,
    isPending: isCampaignUpdatePending,
    isError: isCampaignUpdateFailed,
    isSuccess: isCampaignUpdated,
  } = useUpdateCampaign();

  const {
    isError: isCampaignDataFailed,
    isPending: isCampaignDataLoading,
    data: campaignData,
  } = useSingleCampaignDetails(campaignId, selectedAdvertiser);

  const { data: strategyInfo } = useCampaignStrategyInfo(
    campaignId,
    selectedAdvertiser
  );
  const campaignStartDate = new Date(strategyInfo?.campaignStartDate.value);
  const campaignEndDate = new Date(strategyInfo?.campaignEndDate.value);
  const campaignDurationInMilliseconds =
    campaignEndDate.getTime() - campaignStartDate.getTime();
  const campaignDurationInDays =
    campaignDurationInMilliseconds / (1000 * 60 * 60 * 24);

  const totalBudget =
    campaignData?.campaignBudget &&
    Math.ceil(
      (campaignData?.campaignBudget.budget / 30) * campaignDurationInDays
    );

  const { data: campaignOwnerData } = useAdvertiser(selectedAdvertiser);

  const categorizedFields = categorizeFields(
    campaignData?.campaignFieldValues,
    true
  );
  const generalCampaignInfoArr = categorizedFields["GENERAL"];
  const ADVNACED_TARGETING_DATA =
    categorizedFields && categorizedFields["ADVANCE_TARGETING"];
  const GEO_TARGETING_DATA =
    categorizedFields && categorizedFields["GEO_TARGETING"];

  const addressField = GEO_TARGETING_DATA?.find(
    (field) => field.campaignFieldName.toLowerCase() === "address"
  );

  const radiusField = GEO_TARGETING_DATA?.find(
    (field) => field.campaignFieldName.toLowerCase() === "radius"
  );

  let campaignWebsite = campaignData?.websiteUrl;

  const { data: keywordsData } = useKeywords(campaignId);
  let formattedKeywords = "";

  if (keywordsData && keywordsData[0]?.keywords) {
    formattedKeywords = keywordsData[0].keywords
      .map((keyword) => `• ${keyword} `)
      .join(" ");

    if (formattedKeywords.length > 500) {
      formattedKeywords = formattedKeywords.slice(0, 500 - 3).trim() + "...";
    }
  }

  const campaignDisplayImage =
    campaignData?.campaignImages?.displayAds[0]?.imageUrl;
  const campaignDisplayImage2 =
    campaignData?.campaignImages?.displayAds[1]?.imageUrl;
  const campaignSocialImages =
    campaignData?.campaignImages?.socialAds[0]?.imageUrl;
  const campaignSearchImages =
    campaignData?.campaignImages?.searchAds[0]?.imageUrl;

  const chartRef = useRef<HTMLDivElement | null>(null);
  const [pieChartImageSrc, setPieChartImageSrc] = useState("");

  const [isPDFPending, setIsPDFPending] = useState<undefined | boolean>(
    undefined
  );

  const campaignProps = {
    setIsPDFPending,
    pieChartImageSrc,
    generalCampaignInfoArr,
    advanceTargetingCampaignInfoArr: ADVNACED_TARGETING_DATA,
    geoTargetingCampaignInfoArr: GEO_TARGETING_DATA,
    campaignName: campaignData?.name,
    campaignOwnerData,
    totalBudget,
    campaignData,
    campaignKeywordsFormatted: formattedKeywords,
    campaignDisplayImage,
    campaignDisplayImage2,
    campaignSocialImages,
    campaignSearchImages,
  };

  useEffect(() => {
    if (chartRef.current && campaignData?.campaignBudget?.budget) {
      toPng(chartRef.current)
        .then((dataUrl) => {
          setPieChartImageSrc(dataUrl);
        })
        .catch((err) => {});
    }
  }, [chartRef, campaignData?.campaignBudget?.budget]);

  useEffect(() => {
    if (isCampaignUpdated) {
      navigate("/campaigns");
    }
  }, [isCampaignUpdated, navigate]);

  return isCampaignDataLoading ? (
    <ActivitySpinnerNoBg />
  ) : campaignData ? (
    <ContentContainer className="p-7">
      {isCampaignUpdatePending && <ActivityIndicator />}
      {isPDFPending && <ActivityIndicator />}
      <div className="flex flex-col space-y-4">
        <section id="generalCampaignInfo">
          <RoundedButton
            type="button"
            onClick={() => {
              if (pieChartImageSrc) {
                setIsPDFPending(true);
                SavePDF({ ...campaignProps });
              }
            }}
            className="py-3 px-9 max-w-fit  ml-auto "
            borderRadius="rounded-md"
            text={
              <p className=" text-sm text-white inline-flex items-center font-interRegular">
                PDF
              </p>
            }
          />
          <div className="flex flex-col w-full">
            <h3 className="text-sm w-full mb-2.5 font-interSemiBold text-primary">
              Campaign Info
            </h3>

            <div>
              <p className="font-interRegular text-sm text-primary">
                <span className="mr-1 text-sm font-interSemiBold text-primary">
                  • Campaign designed for:
                </span>
                {campaignOwnerData?.displayName}
              </p>

              {generalCampaignInfoArr?.map((campaignFieldsObj) => {
                const fieldTitle = campaignFieldsObj.campaignFieldName;
                const fieldVal = campaignFieldsObj.campaignFieldName
                  .toLocaleLowerCase()
                  .includes("date")
                  ? formatDateWithTime(campaignFieldsObj.value)
                  : campaignFieldsObj.value;

                return (
                  <p className="font-interRegular my-2 text-primary text-sm">
                    <span className="mr-1 text-sm font-interSemiBold text-primary">
                      • {fieldTitle}:
                    </span>
                    {fieldVal}
                  </p>
                );
              })}

              <p className="font-interRegular my-2 text-primary text-sm">
                <span className="mr-1 text-sm font-interSemiBold text-primary">
                  • Campaign Website Url:
                </span>
                {campaignWebsite}
              </p>

              <p className="font-interRegular my-2 text-primary text-sm">
                <span className="mr-1 text-sm font-interSemiBold text-primary">
                  • Total Budget:
                </span>
                ${totalBudget}
              </p>
            </div>
          </div>
        </section>

        <section id="allocation">
          <h3 className="text-sm text-primary mb-3 font-interSemiBold mt-6">
            Allocation
          </h3>
          <div className="flex flex-col ">
            <div className="flex">
              <div className="w-[40%]">
                <p className="text-primary font-interRegular leading-6 text-sm w-full">
                  Strategic budget allocation is the cornerstone of success for
                  any ad campaign that determines your ROI delivers maximum
                  impact and returns. By carefully analyzing market trends,
                  consumer behavior, and competitive landscapes, your budget
                  allocation plan tailored to your unique business goal
                </p>
              </div>

              <div ref={chartRef} className="w-[60%] ">
                <PieChart
                  data={[
                    {
                      value: campaignData.campaignBudget.displayAllocation,
                      color: "#5e88b6",
                      title: "Display",
                    },
                    {
                      value: campaignData.campaignBudget.socialAllocation,
                      color: "#ff914d",
                      title: "Social",
                    },
                    {
                      value: campaignData.campaignBudget.searchAllocation,
                      color: "#5ce1e6",
                      title: "Search",
                    },
                  ].map((d, index) => ({
                    key: `campaign-pie-${index}`,
                    value: d.value,
                    color: d.color,
                    title: d.title,
                  }))}
                />
              </div>
            </div>
          </div>
        </section>

        <section id="targeting">
          <h3 className="text-sm text-primary mb-3 font-interSemiBold mt-6">
            Targeting
          </h3>
          <div className="flex flex-col">
            <p className="text-sm text-primary mb-2 font-interRegular">
              Our targeting strategy is engineered to pinpoint your ideal
              customers with surgical precision, ensuring every impression
              counts through a combination of advanced data analytics,
              demographic profiling, and psychographic segmentation, we've
              identified the audiences most interested in your business.
            </p>

            <p className="text-sm text-primary mt-1.5 font-interRegular">
              Your ads will be shown around
              <span className="font-interSemiBold mx-1.5">
                {addressField?.value}
              </span>
              for a
              <span className="font-interSemiBold mx-1.5">
                {radiusField?.value}
              </span>
              mile radius targeted for audiences that meet the following traits:
            </p>
            <div className="flex flex-col mt-2">
              {ADVNACED_TARGETING_DATA?.map((field) => {
                return (
                  <div className="flex ">
                    <p className="text-sm text-primary font-interRegular">
                      •
                      <span className="mx-1.5 text-sm text-primary font-interSemiBold">
                        {field.campaignFieldName}:
                      </span>
                      {parseDefaultVal(field.value)}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section id="search-keywords">
          <div>
            <h3 className="text-sm text-primary  font-interSemiBold mt-6">
              Search keywords
            </h3>
            <p className="font-interRegular text-sm text-primary mt-1.5">
              Your ads will appear for users who are actively searching for
              these topics on search engines.
            </p>
            <p className=" font-interSemiBold text-sm text-primary">
              {formattedKeywords}
            </p>
          </div>
        </section>
      </div>
      <div className="ml-auto mt-10 flex">
        {isCampaignUpdateFailed && (
          <div className="self-center mr-6">
            <p className="text-sm font-interSemiBold text-colorDelete">
              Something went wrong with starting your campaign !
            </p>
          </div>
        )}
        <RoundedButton
          onClick={() => setStep(3)}
          type="button"
          className="py-3 px-9 max-w-fit max-h-fit ml-auto mt-auto mr-5"
          borderRadius="rounded-md"
          bgColor="#FFFFFF"
          borderColor="#C1C9D2"
          text={
            <p className=" text-sm text-primary inline-flex items-center font-interRegular">
              Previous
            </p>
          }
        />
        <RoundedButton
          onClick={async () => {
            if (campaignData.status && campaignData.status !== "active") {
              await updateCampaign({ campaignId, data: { status: "active" } });
              return;
            }
            navigate("/campaigns");
          }}
          type="button"
          isEnabled={true}
          className="py-3 px-9 max-w-fit max-h-fit ml-auto mt-auto "
          borderRadius="rounded-md"
          text={
            <p className=" text-sm text-white inline-flex items-center font-interRegular">
              {campaignData?.status === "active"
                ? "Go Back to Campaigns"
                : isCampaignUpdatePending
                ? "Processing..."
                : "Start my Campaign"}
            </p>
          }
        />
      </div>
    </ContentContainer>
  ) : (
    isCampaignDataFailed && (
      <div>
        <p className="text-colorDelete text-sm font-interSemiBold">
          Something went wrong when trying to load campaign data
        </p>
      </div>
    )
  );
};

export default Confirm;
