import { useParams } from "react-router-dom";

import { useSelectedAdvertiser } from "../../../contexts/selectedAdvertiser";

import { categorizeFields } from "../../wizard/steps/campaign/categorizeFields";

import ActivityIndicator from "../../../components/activitySpinner";
import ImageModal from "../../../components/modal/imageModal";
import PieChart from "../../../components/pieChart";
import Navbar from "../../../components/navBar";

import { parseDefaultVal } from "../../../utils/parseDefaultValues";

import { useAdvertiser } from "../../../services/advertiser";
import { useSingleCampaignDetails } from "../../../services/campaigns";

import PdfIcon from "../../../assets/icons/pdf-icon.svg";

import { formatDateWithTime } from "../../../utils/formatDateWithTime";

const Index = () => {
  const { selectedAdvertiser } = useSelectedAdvertiser();
  const { campaignId } = useParams<{ campaignId: string }>();
  const campaignIdToNumber = campaignId ? parseInt(campaignId) : undefined;

  const { data: campaignOwnerData } = useAdvertiser(selectedAdvertiser);
  const {
    isError: isCampaignDataFailed,
    isPending: isCampaignDataLoading,
    data: campaignData,
  } = useSingleCampaignDetails(campaignIdToNumber, selectedAdvertiser);

  let campaignWebsite = campaignData?.websiteUrl;

  const campaignKeywords = campaignData?.campaignKeywords;
  let formattedKeywords = "";

  if (campaignKeywords && campaignKeywords[0].keywords) {
    formattedKeywords = campaignKeywords[0].keywords
      .map((keyword) => `• ${keyword} `)
      .join(" ");

    if (formattedKeywords.length > 500) {
      formattedKeywords = formattedKeywords.slice(0, 500 - 3).trim() + "...";
    }
  }

  const campaignDisplayImage =
    campaignData?.campaignImages?.displayAds[0]?.imageUrl;
  const campaignDisplayImage2 =
    campaignData?.campaignImages?.displayAds[1]?.imageUrl;
  const campaignSocialImages =
    campaignData?.campaignImages?.socialAds[0]?.imageUrl;
  const campaignSearchImages =
    campaignData?.campaignImages?.searchAds[0]?.imageUrl;

  const categorizedFields = categorizeFields(
    campaignData?.campaignFieldValues,
    true
  );
  const generalCampaignInfoArr = categorizedFields["GENERAL"];
  const advanceTargetingCampaignInfoArr =
    categorizedFields["ADVANCE_TARGETING"];
  const geoTargetingCampaignInfoArr = categorizedFields["GEO_TARGETING"];

  // const campaignProps = {
  //   generalCampaignInfoArr,
  //   advanceTargetingCampaignInfoArr,
  //   geoTargetingCampaignInfoArr,
  //   campaignName: campaignData?.name,
  //   campaignOwnerData,
  //   campaignData,
  //   campaignKeywordsFormatted: formattedKeywords,
  //   campaignDisplayImage,
  //   campaignDisplayImage2,
  //   campaignSocialImages,
  //   campaignSearchImages,
  // };

  const customNavLinkContainer = () => {
    return (
      <div className="flex items-center mr-10">
        <div
          // onClick={() => SavePDF({ ...campaignProps })}
          className="flex items-center cursor-pointer"
        >
          <div className="w-8 h-8 mr-2 object-cover">
            <img src={PdfIcon} alt="pdf-icon.svg" />
          </div>
          <p className="text-white text-sm font-ManropeRegular">Download PDF</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <Navbar
        isEmailVerified={true}
        customNavLinkContainer={customNavLinkContainer()}
      />
      <div className="flex mx-auto justify-self-center flex-1  mt-14 pl-4  xl:w-[1280px] lg:w-[1208px] md:w-[920px] sm:w-[850px] w-[730px] xl:pl-0 pt-0 ">
        <div className="pt-8 w-full ">
          {isCampaignDataFailed && (
            <div className="flex justify-center">
              <p className="text-red-400 font-ManropeSemiBold text-lg">
                Something went wrong with loading campaign details. Please try
                again!
              </p>
            </div>
          )}
          {isCampaignDataLoading && <ActivityIndicator />}
          {campaignData && (
            <>
              <div className="flex flex-col">
                <div className="flex flex-col pt-4 w-full my-3">
                  <h3 className="text-xl w-full border-b pb-3 border-gray-200 font-ManropeBold text-[#0e086a]">
                    Campaign Info
                  </h3>

                  <div className="my-4 pl-2">
                    <p className="font-ManropeRegular">
                      <span className="font-ManropeSemiBold mr-1">
                        Campaign for:
                      </span>
                      {campaignOwnerData?.displayName}
                    </p>

                    {generalCampaignInfoArr?.map((campaignFieldsObj) => {
                      const fieldTitle = campaignFieldsObj.campaignFieldName;
                      const fieldVal = campaignFieldsObj.campaignFieldName
                        .toLocaleLowerCase()
                        .includes("date")
                        ? formatDateWithTime(campaignFieldsObj.value)
                        : campaignFieldsObj.value;

                      return (
                        <p className="font-ManropeRegular my-2">
                          <span className="font-ManropeSemiBold mr-1">
                            {fieldTitle}:
                          </span>
                          {fieldVal}
                        </p>
                      );
                    })}

                    <p className="font-ManropeRegular my-2">
                      <span className="font-ManropeSemiBold mr-1">
                        Campaign Website Url:
                      </span>
                      {campaignWebsite}
                    </p>

                    <p className="font-ManropeRegular my-2">
                      <span className="font-ManropeSemiBold mr-1">
                        Total Budget:
                      </span>
                      ${campaignData.campaignBudget.budget}
                    </p>
                  </div>
                </div>

                <div className=" w-full my-3">
                  <h3 className="text-xl  border-b pb-3 border-gray-200 font-ManropeBold text-[#0e086a]">
                    Allocation
                  </h3>

                  <div className="flex items-center">
                    <div className="my-4 pl-2 w-[70%]">
                      <p className="font-ManropeRegular max-w-[75%] mb-8 leading-7">
                        Strategic budget allocation is the cornerstone of
                        success for any ad campaign that determines your ROI
                        delivers maximum impact and returns. By carefully
                        analyzing market trends, consumer behavior, and
                        competitive landscapes, your budget allocation plan
                        tailored to your unique business goals are
                      </p>
                      <p className="font-ManropeRegular">
                        <span className="font-ManropeSemiBold mr-1">
                          Search
                        </span>
                        ${campaignData.campaignBudget.searchAllocation}
                        <span className=" mx-1.5 text-lg ">|</span>
                        <span className="font-ManropeSemiBold mr-1">
                          Social
                        </span>
                        ${campaignData.campaignBudget.socialAllocation}
                        <span className=" mx-1.5 text-lg">|</span>
                        <span className="font-ManropeSemiBold mr-1">
                          Display
                        </span>
                        ${campaignData.campaignBudget.displayAllocation}
                      </p>
                    </div>

                    <div className="my-6">
                      <PieChart
                        data={[
                          {
                            value:
                              (campaignData.campaignBudget.displayAllocation /
                                campaignData.campaignBudget.budget) *
                              100,
                            color: "#5e88b6",
                            title: "Display",
                          },
                          {
                            value:
                              (campaignData.campaignBudget.socialAllocation /
                                campaignData.campaignBudget.budget) *
                              100,
                            color: "#ff914d",
                            title: "Social",
                          },
                          {
                            value:
                              (campaignData.campaignBudget.searchAllocation /
                                campaignData.campaignBudget.budget) *
                              100,
                            color: "#5ce1e6",
                            title: "Search",
                          },
                        ].map((d, index) => ({
                          key: `campaign-pie-${index}`,
                          value: d.value,
                          color: d.color,
                          title: d.title,
                        }))}
                      />
                    </div>
                  </div>
                </div>

                <section className=" w-full my-3">
                  <h3 className="text-xl  border-b pb-3 border-gray-200 font-ManropeBold text-[#0e086a]">
                    Targeting
                  </h3>

                  <div className="my-4 pl-2 w-full">
                    <p className="font-ManropeRegular max-w-[85%] mb-8 leading-7">
                      Our targeting strategy is engineered to pinpoint your
                      ideal customers with surgical precision, ensuring every
                      impression counts. Through a combination of advanced data
                      analytics, demographic profiling, and psychographic
                      segmentation, we've identify the audiences most interested
                      in your business. By delivering the right message to the
                      right people at the right time, we amplify the
                      effectiveness of your advertising efforts, driving
                      tangible results and fostering lasting connections with
                      your target market.
                    </p>

                    <div className="grid grid-cols-2 w-[85%]">
                      {geoTargetingCampaignInfoArr?.map((campaignFieldsObj) => {
                        const fieldTitle = campaignFieldsObj.campaignFieldName;
                        const fieldVal = campaignFieldsObj.campaignFieldName
                          .toLocaleLowerCase()
                          .includes("date")
                          ? formatDateWithTime(campaignFieldsObj.value)
                          : campaignFieldsObj.value;

                        return (
                          <div
                            key={`${fieldTitle}-${fieldVal}`}
                            className="flex flex-col last-of-type:mb-0 mb-5"
                          >
                            <span className="font-ManropeSemiBold mr-2">
                              {fieldTitle === "Address"
                                ? "Where your ads will appear:"
                                : fieldTitle}
                            </span>
                            <span className="font-ManropeRegular">
                              {parseDefaultVal(fieldVal)}
                            </span>
                          </div>
                        );
                      })}
                    </div>

                    <div className=" flex w-[85%] flex-col">
                      <div className="my-5">
                        <h3 className="font-ManropeSemiBold block mb-2">
                          Search keywords:
                        </h3>
                        <p>{formattedKeywords}</p>
                      </div>
                      <div>
                        {advanceTargetingCampaignInfoArr?.map(
                          (campaignFieldsObj) => {
                            const fieldTitle =
                              campaignFieldsObj.campaignFieldName;
                            const fieldVal = campaignFieldsObj.campaignFieldName
                              .toLocaleLowerCase()
                              .includes("date")
                              ? formatDateWithTime(campaignFieldsObj.value)
                              : campaignFieldsObj.value;

                            return (
                              <div
                                key={`${fieldTitle}-${fieldVal}`}
                                className="last-of-type:mb-0 mb-5"
                              >
                                <span className="font-ManropeSemiBold mr-2">
                                  {fieldTitle}:
                                </span>
                                <span className="font-ManropeRegular">
                                  {parseDefaultVal(fieldVal)}
                                </span>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <div className="flex flex-col  mb-4 pb-7">
                <div className="flex flex-col pt-4 w-full mt-3 mb-7 ">
                  <h3 className="text-xl w-full border-b pb-3 border-gray-200 font-ManropeBold text-[#0e086a]">
                    Sample Creative
                  </h3>
                  <p className="font-ManropeRegular max-w-[85%] mt-4 leading-8">
                    The following creative concepts are designed to showcase
                    some samples of how your brand would appear to a ad audience
                    across different media channels. These are tailored to suit
                    your unique brand identity and objectives and are optimized
                    based on our industry experience to ensure it resonates with
                    audiences. The following creative concepts are designed to
                    showcase some samples of how your brand would appear to a ad
                    audience across different media channels . These are
                    tailored to suit your unique brand identity and objectives
                    and are optimized based on our industry experience to ensure
                    it resonates with audiences .
                  </p>
                </div>

                {/* <Masonry
                  breakpointCols={{
                    default: 2,
                    1150: 2,
                    700: 1,
                  }}
                  className="my-masonry-display-grid "
                  columnClassName="campaign-masonry-display-grid_column "
                > */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                  className="space-x-2"
                >
                  {campaignDisplayImage && (
                    <ImageModal
                      divContainerClassName="flex flex-col basis-[48%] mb-[2%]"
                      imgClassName="mb-[2%] object-contain max-h-[30rem]"
                      src={campaignDisplayImage}
                      alt="processing..."
                    />
                  )}
                  {campaignDisplayImage2 && (
                    <ImageModal
                      divContainerClassName="flex flex-col basis-[48%] mb-[2%]"
                      imgClassName="mb-[2%] object-contain max-h-[30rem]"
                      src={campaignDisplayImage2}
                      alt="processing..."
                    />
                  )}
                  {campaignSocialImages && (
                    <ImageModal
                      divContainerClassName="flex flex-col basis-[48%] mb-[2%]"
                      imgClassName="mb-[2%] object-contain max-h-[30rem]"
                      src={campaignSocialImages}
                      alt="processing..."
                    />
                  )}
                  {campaignSearchImages && (
                    <ImageModal
                      divContainerClassName="flex flex-col basis-[48%] mb-[2%]"
                      imgClassName="mb-[2%] object-contain max-h-[30rem]"
                      src={campaignSearchImages}
                      alt="processing..."
                    />
                  )}
                </div>
                {/* </Masonry> */}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Index;
