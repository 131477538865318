import PieChart from "../../../../components/pieChart";

import { ICampaignStrategyResponse } from "../../../../services/campaigns";

import { formatDate } from "../../../../utils/formatDate";

const CampaignStrategyReadMode = ({
  campaignStrategyData,
}: {
  campaignStrategyData: ICampaignStrategyResponse | undefined;
}) => {
  return (
    <div className=" flex">
      <div className="flex flex-col w-[70%]">
        <section id="strategy-summary">
          <h3 className="text-primary font-interSemiBold text-sm">Summary</h3>
          <p className="text-sm text-primary mt-1.5 font-interRegular">
            Your campaign
            <span className="font-interSemiBold mx-1.5">
              {campaignStrategyData?.campaignName}
            </span>
            is designed to achieve a 30 day budget goal of
            <span className="font-interSemiBold mx-1.5">
              ${campaignStrategyData?.campaignBudget.budget}
            </span>
            to drive the goal of
            <span className="font-interSemiBold mx-1.5">
              {campaignStrategyData?.campaignGoal?.value}
            </span>
            . Your budget was determined using a typical allocation, assuming an
            annual revenue of $100,000.
          </p>
          <p className="text-sm text-primary mt-6 font-interRegular">
            The Campaign will run from
            <span className="font-interSemiBold mx-1.5">
              {campaignStrategyData?.campaignStartDate?.value
                ? formatDate(campaignStrategyData?.campaignStartDate?.value)
                : "null date"}
            </span>
            to
            <span className="font-interSemiBold mx-1.5">
              {campaignStrategyData?.campaignEndDate?.value
                ? formatDate(campaignStrategyData?.campaignEndDate?.value)
                : "null date"}
            </span>
            with a central theme of
            <span className="font-interSemiBold mx-1.5">
              {campaignStrategyData?.campaignTheme?.value ?? null}
            </span>
            , which will shape the messaging and outreach effort to boost
            engagement and impact.
          </p>
          <p className="text-sm text-primary mt-6 font-interRegular">
            Feel free to adjust these settings anytime by clicking on “Edit”
          </p>
        </section>

        <section id="strategy-allocation" className="mt-10">
          <h3 className="text-primary font-interSemiBold text-sm mb-1.5">
            Allocation
          </h3>
          <p className="text-sm text-primary font-interRegular">
            Your campaign budget has been allocated strategically to maximize
            impact and ensure optimal reach to your target audience while
            maintaining cost efficiency.
          </p>
          <div className="flex mt-6">
            {campaignStrategyData && (
              <PieChart
                topContainer="flex flex-col space-y-3 w-[3rem] order-2"
                indicatorContainer="flex items-center w-24"
                circleContainer="relative w-[25rem]"
                data={[
                  {
                    value:
                      campaignStrategyData.campaignBudget.displayAllocation,
                    color: "#5e88b6",
                    title: "Display",
                  },
                  {
                    value: campaignStrategyData.campaignBudget.socialAllocation,
                    color: "#ff914d",
                    title: "Social",
                  },
                  {
                    value: campaignStrategyData.campaignBudget.searchAllocation,
                    color: "#5ce1e6",
                    title: "Search",
                  },
                ].map((d, index) => ({
                  key: `campaign-strategy-pie-${index}`,
                  value: d.value,
                  color: d.color,
                  title: d.title,
                }))}
              />
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default CampaignStrategyReadMode;
