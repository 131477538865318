import { UseFormRegister, UseFormSetValue } from "react-hook-form";

import { Selection } from "../../components/select";
import Tooltip from "../../components/tooltip";

import { ReactComponent as TooltipIcon } from "../../assets/icons/tooltip-icon-mui.svg";

const ControlledSelect = ({
  labelName,
  isPending,
  data,
  value,
  setValue,
  errorFieldMsg,
  requiredMessage,
  register,
  registerFieldName,
  isMulti,
  extractValueOnly,
  tooltipBody,
  tooltipClassName,
  placeholder,
}: {
  labelName: string;
  isPending?: boolean;
  data: any;
  value?: any;
  setValue: UseFormSetValue<any>;
  errorFieldMsg: string | undefined;
  requiredMessage: string;
  register: UseFormRegister<any>;
  registerFieldName: string;
  isMulti: boolean;
  extractValueOnly: boolean;
  tooltipBody?: string;
  tooltipClassName?: string | undefined;
  placeholder?: string;
}) => {
  const handleSelectChange =
    (key: any, isMulti: boolean, extractValueOnly: boolean) =>
    async (selectedOption: any) => {
      if (isMulti) {
        setValue(
          key,
          selectedOption
            ? selectedOption.map((option: any) => option.value)
            : []
        );
        return;
      }
      if (extractValueOnly) {
        setValue(key, selectedOption.value);
      } else {
        setValue(key, selectedOption);
      }
    };

  return (
    <div className="flex">
      <div className="flex flex-col xs:w-[18.75rem] sm:w-[27.5rem]">
        <div className="flex ">
          <label
            className="font-interRegular text-sm text-secondary mb-1"
            htmlFor={registerFieldName}
          >
            {labelName}
          </label>
          {tooltipBody && (
            <Tooltip
              Icon={TooltipIcon}
              bodyText={tooltipBody}
              className={`${tooltipClassName} fill-current text-gray-500 `}
            />
          )}
        </div>
        <Selection
          data={data}
          isPending={isPending}
          {...register(registerFieldName, {
            required: requiredMessage,
            validate: (value) => {
              return value?.value !== null || requiredMessage;
            },
          })}
          closeMenuOnSelect={true}
          value={value}
          placeholder={placeholder}
          isMulti={undefined}
          customStyle={{
            selectContainer: {
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: `${errorFieldMsg ? "#D34638" : "#40444f"}`,
              borderRadius: "0.625rem",
              width: "100%",
              fontSize: 14,
              marginTop: "1px",
            },
            valueContainer: {
              paddingInline: "0.75rem",
              paddingBlock: "0.43rem",
            },
            color: "#d3d3d3",
            optionisSelectedBgColor: "transparent",
            optionisFocusedBgColor: "#335498",
            optionisFocusedTextColor: "white",
            singleValStyles: {
              backgroundColor: "transparent",
            },
          }}
          onChange={handleSelectChange(
            registerFieldName,
            isMulti,
            extractValueOnly
          )}
        />
      </div>
      {errorFieldMsg && (
        <div className="self-center mt-3">
          <p className="text-sm  ml-2 font-interSemiBold text-colorDelete ">
            {errorFieldMsg}
          </p>
        </div>
      )}
    </div>
  );
};

export default ControlledSelect;
