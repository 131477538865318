import { useMutation, useQuery } from "@tanstack/react-query";
import { request } from "../axios";
import { queryClient } from "../../App";

export interface IWebDetailsRequest {
  shortName: string;
  url: string;
  websiteTypeId: number | undefined;
  advertiserId: number;
}

export interface IWebDetailsResponse {
  id: number;
  shortName: string;
  url: string;
  advertiserId: number;
  websiteTypeId: number | null;
  createdAt: Date;
  updatedAt: Date;
  image: string;
  numberOfAssets: number;
  isEditable: boolean;
  isFieldsFilled: boolean;
}

/* Adding new website  */
const addLink = (data: IWebDetailsRequest) => {
  return request({
    url: "website-details/",
    method: "post",
    data,
  });
};

export const useAddLink = (advertiserId: number | null) =>
  useMutation({
    mutationKey: ["addedLink"],
    mutationFn: addLink,
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: ["advertiserSites", advertiserId],
      });
    },
    throwOnError: false,
  });

/*GET one website by website id */
const getOneWebsite = (
  websiteId: string | undefined
): Promise<IWebDetailsResponse> => {
  return request({
    url: `website-details/${websiteId}`,
    method: "get",
  });
};

export const useWebsite = (websiteId: string | undefined) => {
  return useQuery({
    queryKey: ["website", websiteId],
    queryFn: () => getOneWebsite(websiteId),
    enabled: !!websiteId,
  });
};

/* GET all websites users requested */
const getUserWebsites = (
  advertiserId: number | null
): Promise<IWebDetailsResponse[]> => {
  return request({
    url: `website-details/advertiser/${advertiserId}`,
    method: "get",
  });
};
export const useAdvertiserSites = (advertiserId: number | null) => {
  return useQuery({
    queryKey: ["advertiserSites", advertiserId],
    queryFn: () => getUserWebsites(advertiserId),
    enabled: !!advertiserId,
  });
};

/* Deleting the website  */
const removeLink = (linkId: string) => {
  return request({
    url: `website-details/${linkId}`,
    method: "delete",
  });
};

export const useRemoveLink = (advertiserId: number | null) =>
  useMutation({
    mutationFn: removeLink,
    onSuccess: (result) => {
      queryClient.setQueryData(["removeLink"], result.data);
      queryClient.invalidateQueries({
        queryKey: ["advertiserSites", advertiserId],
      });
    },
  });
