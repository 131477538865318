import { createRef, useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Bounce, toast, ToastContainer } from "react-toastify";

import { useSelectedAdvertiser } from "../../../../contexts/selectedAdvertiser";
import { useCampaign } from "../../../../contexts/campaign";

import ControlledSelect from "../../../controlledSelect";

import InputWithToggle from "../../../../components/input/inputWithToggle";
import RoundedButton from "../../../../components/roundedButton";
import ActivityIndicator from "../../../../components/activitySpinner";

import CalendarIcon from "../../../../assets/icons/calendar.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit-icon-mui.svg";

import {
  ICampaignStrategyInfoRequest,
  ICampaignStrategyResponse,
  useUpdateCampaignStrategy,
} from "../../../../services/campaigns";
import { useSuggestedAllocations } from "../../../../services/wizard/budget";
import { useAdvertiser } from "../../../../services/advertiser";

type TSuggestedAllocationProps = {
  searchAllocation: number | undefined;
  socialAllocation: number | undefined;
  displayAllocation: number | undefined;
  paidEmail: number | undefined;
};

const CampaignStrategyEditMode = ({
  campaignStrategyData,
  setIsEditEnabled,
}: {
  campaignStrategyData: ICampaignStrategyResponse | undefined;
  setIsEditEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { selectedAdvertiser } = useSelectedAdvertiser();
  const { campaignId } = useCampaign();

  const {
    isSuccess: isStrategyUpdateSuccess,
    isPending: isStrategyUpdatePending,
    isError: isStrategyUpdateFailed,
    mutateAsync: updateStrategyInfo,
  } = useUpdateCampaignStrategy(selectedAdvertiser, campaignId);

  const { mutateAsync: getSuggestedAllocations } = useSuggestedAllocations();

  const { data: advertiserData } = useAdvertiser(selectedAdvertiser);
  const {
    register,
    watch,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm({
    defaultValues: {
      campaignName: campaignStrategyData?.campaignName,
      budget: campaignStrategyData?.campaignBudget?.budget,
      goal: {
        value: campaignStrategyData?.campaignGoal.value,
        label: campaignStrategyData?.campaignGoal.value,
      },
      startDate: campaignStrategyData?.campaignStartDate?.value,
      endDate: campaignStrategyData?.campaignEndDate?.value,
      theme: campaignStrategyData?.campaignTheme?.value,
      searchAllocation: campaignStrategyData?.campaignBudget?.searchAllocation,
      socialAllocation: campaignStrategyData?.campaignBudget?.socialAllocation,
      displayAllocation:
        campaignStrategyData?.campaignBudget?.displayAllocation,
      paidEmail: campaignStrategyData?.campaignBudget?.paidEmail,
    },
  });

  const [suggestedAllocations, setSuggestedAllocations] =
    useState<TSuggestedAllocationProps>({
      searchAllocation: campaignStrategyData?.campaignBudget?.searchAllocation,
      socialAllocation: campaignStrategyData?.campaignBudget?.socialAllocation,
      displayAllocation:
        campaignStrategyData?.campaignBudget?.displayAllocation,
      paidEmail: campaignStrategyData?.campaignBudget?.paidEmail,
    });

  const formValues = watch();

  const formattedGoals =
    campaignStrategyData?.campaignGoal.options.map((goal) => {
      return {
        value: goal,
        label: goal,
      };
    }) ?? [];

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const [datePickerRefs, setDatePickerRefs] = useState<{
    [key: string]: React.RefObject<DatePicker>;
  }>({});

  function getDatePickerRef(fieldName: string) {
    if (datePickerRefs && !datePickerRefs[fieldName]) {
      datePickerRefs[fieldName] = createRef<DatePicker>();
      setDatePickerRefs({ ...datePickerRefs });
    }
    return datePickerRefs[fieldName];
  }

  function handleIconClick(fieldName: string) {
    if (datePickerRefs[fieldName]?.current) {
      datePickerRefs[fieldName].current?.setOpen(true);
    }
  }

  async function onSubmit() {
    const totalAllocation =
      (formValues.searchAllocation ?? 0) +
      (formValues.displayAllocation ?? 0) +
      (formValues.socialAllocation ?? 0) +
      (formValues.paidEmail ?? 0);

    if (totalAllocation !== 100) {
      setError("displayAllocation", {
        type: "manual",
        message: "Allocation total percentages must equal 100",
      });
      return;
    } else {
      clearErrors("displayAllocation");
    }

    if (campaignStrategyData) {
      const reqData: ICampaignStrategyInfoRequest = {
        campaignId: campaignStrategyData?.campaignId,
        campaignName: formValues.campaignName,
        campaignBudget: {
          id: campaignStrategyData.campaignBudget.id,
          budget: formValues.budget,
          searchAllocation: formValues.searchAllocation,
          socialAllocation: formValues.socialAllocation,
          displayAllocation: formValues.displayAllocation,
        },
        campaignGoal: {
          id: campaignStrategyData.campaignGoal.id,
          value: formValues.goal.value,
        },
        campaignStartDate: {
          id: campaignStrategyData.campaignStartDate.id,
          value: formValues.startDate,
        },
        campaignEndDate: {
          id: campaignStrategyData.campaignEndDate.id,
          value: formValues.endDate,
        },
        campaignTheme: {
          id: campaignStrategyData.campaignTheme.id,
          value: formValues.theme,
        },
        campaignBudgetEstimation: {
          ...campaignStrategyData.campaignBudgetEstimation,
        },
      };
      await updateStrategyInfo({ data: reqData });
    }
  }

  useEffect(() => {
    if (isStrategyUpdateSuccess) {
      toast.success("Successfully updated fields!");
      setTimeout(() => {
        setIsEditEnabled(false);
      }, 1000);
    } else if (isStrategyUpdateFailed) {
      toast.error("Failed to update fields!");
    }
    // eslint-disable-next-line
  }, [isStrategyUpdateSuccess, isStrategyUpdateFailed]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        newestOnTop={true}
        closeOnClick
        hideProgressBar={false}
        theme="light"
        transition={Bounce}
      />
      {isStrategyUpdatePending && <ActivityIndicator />}
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-sm text-primary uppercase font-interSemiBold mr-1.5 mb-1.5">
          campaign settings
        </h2>
        <div className="flex flex-col space-y-6">
          <InputWithToggle
            type="text"
            name="campaignName"
            label="Campaign Name"
            register={register}
            reactHooksFormEnabled={true}
            requiredMessage="Campaign Name is required"
            validation={errors.campaignName?.message}
            validationClassName="text-colorDelete ml-3 text-sm"
            className={`${
              errors.campaignName?.message && "border-[#D34638] "
            }   `}
          />
          <InputWithToggle
            type="number"
            name="budget"
            label="Monthly (30 day) Budget"
            register={register}
            reactHooksFormEnabled={true}
            requiredMessage="Budget is required"
            minValue={100}
            validation={errors.budget?.message}
            onBlurFunc={async () => {
              if (
                formValues.budget &&
                formValues.budget >= 100 &&
                advertiserData?.industryId
              ) {
                const reqData = {
                  budget: formValues.budget,
                  industryId: parseInt(advertiserData?.industryId),
                  organizationId: advertiserData?.organizationId,
                };
                const response = await getSuggestedAllocations(reqData);
                setSuggestedAllocations({
                  displayAllocation: response.displayAllocation,
                  searchAllocation: response.searchAllocation,
                  paidEmail: response.paidEmail,
                  socialAllocation: response.socialAllocation,
                });
              }
            }}
            validationClassName="text-colorDelete ml-3 text-sm"
            className={`${errors.budget?.message && "border-[#D34638] "}   `}
          />
          <ControlledSelect
            data={formattedGoals}
            errorFieldMsg={errors.goal?.message}
            isMulti={false}
            extractValueOnly={false}
            labelName="Goal"
            register={register}
            setValue={setValue}
            registerFieldName="goal"
            value={formValues.goal}
            requiredMessage="Goal is required"
          />

          <div className="flex w-[27.5rem]">
            <div className="flex flex-col xs:w-[8.75rem] sm:w-[11.5rem]">
              <label className="font-interRegular text-sm text-secondary mb-1">
                Start Date
              </label>
              <DatePicker
                id="startDate"
                ref={getDatePickerRef("startDate")}
                showIcon
                icon={
                  <img
                    className="absolute cursor-pointer right-1.5 top-2"
                    onClick={() => handleIconClick("startDate")}
                    src={CalendarIcon}
                    alt="calendar"
                  />
                }
                selected={
                  formValues.startDate ? new Date(formValues.startDate) : null
                }
                onChange={(date) => {
                  if (date) {
                    const isoDate = date.toISOString();
                    setValue("startDate", isoDate, { shouldValidate: true });
                  } else {
                    setValue("startDate", null, { shouldValidate: true });
                  }
                }}
                dateFormat="MM/dd/yyyy"
                className={`${
                  errors.startDate ? "border-[#D34638]" : "border-[#40444f]"
                } w-full border-[1px] border-solid rounded-[0.625rem] font-interRegular text-sm text-primary datepicker-wrapper`}
                placeholderText=""
                minDate={today}
                maxDate={
                  formValues.endDate ? new Date(formValues.endDate) : null
                }
              />
              <input
                type="hidden"
                {...register("startDate", {
                  validate: (value) =>
                    value ? true : "Start Date is required",
                })}
              />
              {errors.startDate && (
                <p className="text-colorDelete text-sm font-interSemiBold">
                  {errors.startDate.message as string}
                </p>
              )}
            </div>
            <div className="mx-auto self-center ">
              <p className="text-sm text-secondary mt-4  font-interRegular">
                TO
              </p>
            </div>
            <div className="flex flex-col xs:w-[8.75rem] sm:w-[11.5rem]">
              <label className="font-interRegular text-sm text-secondary mb-1">
                End Date
              </label>
              <DatePicker
                id="endDate"
                ref={getDatePickerRef("endDate")}
                showIcon
                icon={
                  <img
                    className="absolute cursor-pointer right-1.5 top-2"
                    onClick={() => handleIconClick("endDate")}
                    src={CalendarIcon}
                    alt="calendar"
                  />
                }
                selected={
                  formValues.endDate ? new Date(formValues.endDate) : null
                }
                onChange={(date) => {
                  if (date) {
                    const isoDate = date.toISOString();
                    setValue("endDate", isoDate);
                  } else {
                    setValue("endDate", null);
                  }
                }}
                dateFormat="MM/dd/yyyy"
                className={`${
                  errors.endDate ? "border-[#D34638]" : "border-[#40444f]"
                } w-full border-[1px] border-solid rounded-[0.625rem] font-interRegular text-sm text-primary datepicker-wrapper`}
                placeholderText=""
                minDate={formValues.endDate ? formValues.endDate : today}
              />
              <input
                type="hidden"
                {...register("endDate", {
                  validate: (value) => (value ? true : "End Date is required"),
                })}
              />
              {errors.endDate && (
                <p className="text-colorDelete text-sm font-interSemiBold">
                  {errors.endDate.message as string}
                </p>
              )}
            </div>
          </div>
          <InputWithToggle
            type="text"
            name="theme"
            label="Theme"
            register={register}
            toolTipEnabled={true}
            tooltipText={`
              An advertising theme is the central idea or message that runs throughout an advertising campaign. It ties together all the individual ads and marketing materials, ensuring consistency and cohesion. The theme often reflects the brand's identity, values, or a specific product feature and is designed to resonate with the target audience, making the campaign more memorable and effective. For example, a theme could be "Valentine day” for a restaurant  hoping to drive customers to their business for the day
              `}
            reactHooksFormEnabled={true}
            requiredMessage="Theme is required"
            validation={errors.theme?.message}
            validationClassName="text-colorDelete ml-3 text-sm"
            className={`${errors.theme?.message && "border-[#D34638] "}   `}
          />
        </div>

        <h2 className="text-sm text-primary uppercase font-interSemiBold mt-20 mr-1.5 mb-1.5">
          budget allocation
        </h2>
        <div className="grid grid-cols-[2fr_1fr_1.5fr] gap-y-3 space-x-12 w-[27rem]">
          <div className="flex flex-col space-y-6">
            <div className="h-5"></div>
            <p className="text-sm text-primary font-interRegular py-1 px-3">
              Paid Search
            </p>
            <p className="text-sm text-primary font-interRegular py-1 px-3">
              Paid Social
            </p>
            <p className="text-sm text-primary font-interRegular py-2 px-3">
              Paid Email
            </p>
            <p className="text-sm text-primary font-interRegular py-2 px-3">
              Paid Display
            </p>
          </div>

          <div className="flex flex-col space-y-6">
            <h3 className="text-sm text-secondary font-interRegular">
              Suggested
            </h3>

            <p className="text-sm text-primary py-1 px-3 font-interRegular">
              {suggestedAllocations.searchAllocation}%
            </p>

            <p className="text-sm text-primary py-1 px-3 font-interRegular">
              {suggestedAllocations.socialAllocation}%
            </p>

            <p className="text-sm text-primary py-2 px-3 font-interRegular">
              {suggestedAllocations.paidEmail}%
            </p>
            <p className="text-sm text-primary py-2 px-3 font-interRegular">
              {suggestedAllocations.displayAllocation}%
            </p>
          </div>

          <div className="flex flex-col space-y-6">
            <h3 className="text-sm text-secondary  font-interRegular">
              Current
            </h3>
            <InputWithToggle
              type="number"
              name="searchAllocation"
              label=""
              register={register}
              reactHooksFormEnabled={true}
              requiredMessage="Search Allocation is required"
              validation={errors.searchAllocation?.message}
              customWidth="w-full"
              customInputLabelContainer="relative"
              validationClassName="text-colorDelete ml-3 text-sm absolute left-full top-1 w-[20rem]"
              customPadding="py-1 px-3"
              className={`w-full ${
                errors.searchAllocation?.message && "border-[#D34638] "
              }`}
            />
            <InputWithToggle
              type="number"
              name="socialAllocation"
              label=""
              register={register}
              customWidth="w-full"
              reactHooksFormEnabled={true}
              requiredMessage="Social Allocation is required"
              validation={errors.socialAllocation?.message}
              customInputLabelContainer="relative"
              validationClassName="text-colorDelete ml-3 text-sm absolute left-full top-1 w-[20rem]"
              customPadding="py-1 px-3"
              className={`${
                errors.socialAllocation?.message && "border-[#D34638] "
              }   `}
            />
            <InputWithToggle
              type="number"
              name="paidEmail"
              label=""
              register={register}
              customWidth="w-full"
              reactHooksFormEnabled={true}
              requiredMessage="Paid Email is required"
              validation={errors.paidEmail?.message}
              customInputLabelContainer="relative"
              validationClassName="text-colorDelete ml-3 text-sm absolute left-full top-1 w-[20rem]"
              customPadding="py-1 px-3"
              className={`${
                errors.paidEmail?.message && "border-[#D34638] "
              }   `}
            />
            <InputWithToggle
              type="number"
              name="displayAllocation"
              label=""
              register={register}
              customWidth="w-full"
              reactHooksFormEnabled={true}
              requiredMessage="Display Allocation is required"
              validation={
                errors.displayAllocation?.type === "required" &&
                errors.displayAllocation?.message
              }
              customInputLabelContainer="relative"
              validationClassName="text-colorDelete ml-3 text-sm absolute left-full top-1 w-[20rem]"
              customPadding="py-1 px-3"
              className={`${
                errors.displayAllocation?.message &&
                errors.displayAllocation.type === "required" &&
                "border-[#D34638] "
              }   `}
            />
          </div>
        </div>
        {errors.displayAllocation?.message ===
          "Allocation total percentages must equal 100" && (
          <p className="text-colorDelete text-sm font-interSemiBold mt-10 ">
            {errors.displayAllocation?.message}
          </p>
        )}
        <div
          className={`flex items-center ${
            errors.displayAllocation?.message ===
            "Allocation total percentages must equal 100"
              ? "mt-10"
              : "mt-20"
          }`}
        >
          <RoundedButton
            type="submit"
            className="py-2.5 px-4"
            borderRadius="rounded-md"
            // onClick={() => {
            //   setIsEditEnabled(false);
            // }}
            text={
              <p className=" text-sm text-white inline-flex items-center font-interRegular">
                <EditIcon className="text-white fill-current mr-2 text-2xl " />
                SAVE
              </p>
            }
          />
          <p
            onClick={() => {
              setIsEditEnabled(false);
            }}
            className="text-sm cursor-pointer font-interRegular ml-7 text-colorBlue"
          >
            Cancel
          </p>
        </div>
      </form>
    </div>
  );
};

export default CampaignStrategyEditMode;
