import React, { useEffect, useState } from "react";

import ContentContainer from "../../../../components/contentContainer";
import RoundedButton from "../../../../components/roundedButton";
import { Selection } from "../../../../components/select";
import TableImageModal from "../../../../components/modal/tableImageModal";

import { IBannerBearImagesResponse } from "../../../../services/wizard/bannerbear";

interface IAds {
  adsFromEach: IBannerBearImagesResponse | undefined;
  setStep: (step: number) => void;
}

const Ads: React.FC<IAds> = ({ adsFromEach, setStep }) => {
  const [adCategoryselection, setAdCategorySelection] = useState<{
    label: string;
    value: string;
  }>({
    label: "Display",
    value: "displayAds",
  });

  const [previewedImage, setPreviewedImage] = useState<{
    id: number;
    imageUrl: string | null;
  } | null>(null);

  const [modalImage, setModalImage] = useState<string | null>(null);

  useEffect(() => {
    if (
      adCategoryselection?.value &&
      adsFromEach
      // adsFromEach[
      //   adCategoryselection?.value as keyof IBannerBearImagesResponse
      // ][0]
    ) {
      const adCategoryKey =
        adCategoryselection?.value as keyof IBannerBearImagesResponse;
      const ad = adsFromEach[adCategoryKey];
      const selected = ad[0] ?? ad[1] ?? ad[2];

      setPreviewedImage({
        id: selected?.id,
        imageUrl: selected?.imageUrl,
      });
    }
    // eslint-disable-next-line
  }, [adCategoryselection?.value]);

  function handlePreviewedImage(id: number, imageUrl: string | null) {
    setPreviewedImage({
      id,
      imageUrl,
    });
  }

  return (
    <>
      {modalImage && (
        <TableImageModal
          isOpen={modalImage}
          src={modalImage}
          onClose={() => setModalImage(null)}
        />
      )}

      <ContentContainer className="p-7">
        <div>
          <p className="text-primary font-interRegular text-sm leading-6">
            These creative offer a glimpse of how your brand can engage with
            audiences across different media channels. Each creative is
            carefully tailored to reflect your unique brand identity and
            objectives, leveraging best practices to ensure maximum impact.
            There’s no need to worry—our system continuously optimizes for you.
            If any creative under performs for whatever reason, it will be
            automatically replaced to ensure you're always putting your best
            foot forward.
          </p>
        </div>
        <hr className=" mt-1.5 mb-3 -mx-10" />

        <div className="flex">
          <div className="w-[200px] flex-col h-[566px] overscroll-y-auto hide-scrollbar  px-2 py-2 flex border border-solid border-[#E5E9EB] rounded-md">
            <div className="w-full mb-2">
              <Selection
                data={[
                  {
                    label: "Display",
                    value: "displayAds",
                  },
                  {
                    label: "Social",
                    value: "socialAds",
                  },
                  {
                    label: "Search",
                    value: "searchAds",
                  },
                ]}
                value={adCategoryselection}
                closeMenuOnSelect={true}
                isMulti={undefined}
                onChange={(selectedOption: any, actionMeta: any) => {
                  setAdCategorySelection(selectedOption);
                }}
                customStyle={{
                  selectContainer: {
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "#40444f",
                    borderRadius: "0.625rem",
                    width: "100%",
                    fontSize: 14,
                    marginTop: "1px",
                  },
                  color: "#d3d3d3",
                  valueContainer: {
                    paddingInline: "0.45rem",
                    paddingBlock: "0.1rem",
                  },
                  optionisSelectedBgColor: "transparent",
                  optionisFocusedBgColor: "#d7edff",
                  optionisFocusedTextColor: "#0e73f6",
                  singleValStyles: {
                    fontSize: 14,
                    backgroundColor: "transparent",
                  },
                  multiValStyles: {
                    backgroundColor: "#d7edff",
                    color: "#0e73f6",
                  },
                  multiValLabelStyles: {
                    color: "#0e73f6",
                  },
                }}
              />
            </div>

            {adsFromEach &&
              adsFromEach[
                adCategoryselection.value as keyof IBannerBearImagesResponse
              ].length !== 0 && (
                <div className="flex flex-col h-full overflow-y-auto hide-scrollbar   gap-y-4 ">
                  {adsFromEach[
                    adCategoryselection.value as keyof IBannerBearImagesResponse
                  ].map((ad) => {
                    return (
                      <div
                        onClick={() => handlePreviewedImage(ad.id, ad.imageUrl)}
                        className={`${
                          previewedImage?.id === ad.id && "bg-[#D7EDFF]"
                        } cursor-pointer w-full -mb-4 last-of-type:mb-0 p-2`}
                        key={ad.id}
                      >
                        <img
                          className="object-contain max-h-[300px]"
                          src={ad.imageUrl ?? undefined}
                          alt={ad.templateType}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
          </div>

          <div className="flex items-center justify-center mx-auto">
            {previewedImage && previewedImage?.id && (
              <div>
                <img
                  onClick={() => setModalImage(previewedImage.imageUrl)}
                  className="object-contain cursor-pointer max-h-[26rem]"
                  src={previewedImage.imageUrl ?? undefined}
                  alt="previewed-item"
                />
              </div>
            )}
          </div>
        </div>

        <div className="ml-auto flex ">
          <RoundedButton
            onClick={() => setStep(2)}
            type="button"
            className="py-3 px-9 max-w-fit max-h-fit ml-auto mt-auto mr-5"
            borderRadius="rounded-md"
            bgColor="#FFFFFF"
            borderColor="#C1C9D2"
            text={
              <p className=" text-sm text-primary inline-flex items-center font-interRegular">
                Previous
              </p>
            }
          />
          <RoundedButton
            onClick={() => setStep(4)}
            type="button"
            isEnabled={true}
            className="py-3 px-9 max-w-fit max-h-fit ml-auto mt-auto "
            borderRadius="rounded-md"
            text={
              <p className=" text-sm text-white inline-flex items-center font-interRegular">
                Next
              </p>
            }
          />
        </div>
      </ContentContainer>
    </>
  );
};

export default Ads;
