import { ReactNode, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../../contexts/auth";
import { useDomain } from "../../contexts/subDomainData";
import { useCampaign } from "../../contexts/campaign";
import { useStep } from "../../contexts/stepper";

import ActivitySpinner from "../../components/activitySpinner";

import { useGetSelf } from "../../services/user";

const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const { user, sessionToken } = useAuth();
  const { data: userBEData } = useGetSelf(sessionToken);

  const { isDomainStyleBeingFetched } = useDomain();

  const location = useLocation();

  const { campaignId, setCampaignId } = useCampaign();
  const { step, setStep } = useStep();

  useEffect(() => {
    if (location.pathname !== "/campaigns/wizard" && step > 0 && campaignId) {
      setCampaignId(undefined);
      setStep(0);
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  if (!user) {
    return <Navigate replace to="/signin" />;
  }

  if (!userBEData || isDomainStyleBeingFetched) {
    return <ActivitySpinner />;
  }

  return (
    <>
      {user.emailVerified === false && (
        <div className="flex bg-red-300 fixed w-full">
          <p className="text-white py-1 pl-2 text-xl w-full">
            Please verify your mail address via link sent to your account, it
            will be restricted otherwise!
          </p>
        </div>
      )}
      {children}
    </>
  );
};

export default PrivateRoute;
